// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/nemanjaz/dev/koye___/gitlab.com/koyot/koyeto-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coming-soon-js": () => import("/Users/nemanjaz/dev/koye___/gitlab.com/koyot/koyeto-site/src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-error-js": () => import("/Users/nemanjaz/dev/koye___/gitlab.com/koyot/koyeto-site/src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("/Users/nemanjaz/dev/koye___/gitlab.com/koyot/koyeto-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("/Users/nemanjaz/dev/koye___/gitlab.com/koyot/koyeto-site/src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-page-2-js": () => import("/Users/nemanjaz/dev/koye___/gitlab.com/koyot/koyeto-site/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/nemanjaz/dev/koye___/gitlab.com/koyot/koyeto-site/.cache/data.json")

